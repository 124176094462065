.uploadContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.uploadInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.icon path:first-of-type {
  fill: var(--secondary-1);
}

.icon path:nth-child(2) {
  fill: var(--secondary-3);
}

.icon path:last-of-type {
  fill: var(--secondary-7);
}

.documentIcon {
  transform: scale(1.2);
}

.documentUploadDragger {
  margin-top: 14px;
  height: 450px !important;
  border-radius: 12px !important;
}

.draggerTitle {
  color: var(--primary-6);
  margin-top: 18px;
  line-height: 1;
}

.allowedFileText {
  color: var(--secondary-4);
  margin-top: 0.5rem;
}
