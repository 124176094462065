.cardContainer {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23eceff2ff' stroke-width='3' stroke-dasharray='8%2c 16' stroke-dashoffset='38' stroke-linecap='square'/%3e%3c/svg%3e");
  border: none;
  border-radius: 8px;
  flex: 1 0 auto;

  & > div:first-child {
    height: 109px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px;
  }
}

.cardContainer h4,
.cardContainer > div:first-of-type > span {
  transition: 0.2s ease-in-out color;
}

.title {
  margin-right: 5px;
  color: var(--secondary-5);
  text-transform: capitalize;
}

.meta {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  & span:last-child {
    font-family: "Poppins", sans-serif !important;
  }
}

.icon {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.icon.delete path {
  fill: var(--red-4);
}

.notSelected h4 {
  color: var(--secondary-3) !important;
}

.secondTitle {
  color: var(--black) !important;
  margin-top: 10px !important;
  margin-bottom: 5px !important;
}
