.creativeUploadDragger {
  margin-top: 14px;
  max-height: 450px;
  border-radius: 12px !important;
}

.creativeFormRow {
  max-width: calc(1000px + 5rem);
  justify-content: space-between;
  margin: 0 auto;
  border-radius: 12px;
  padding: 2.25rem;
  margin-top: -4rem;

  & > div:first-of-type > span:first-of-type {
    width: 25rem;
  }

  &:hover > div:first-of-type {
    background-color: var(--secondary-1);
  }

  &:hover .cardItemWrapper {
    border-radius: 0 0 12px 12px;
  }
}

.creativeSideContainer {
  position: relative;
  width: 400px;
  border-radius: 16px;
  border: 1px solid var(--primary-2);
  background: radial-gradient(
    45.34% 67.16% at 50% 0%,
    var(--primary-2) 0%,
    #fff 100%
  );
  box-shadow: 0px 1px 2px 0px rgba(52, 42, 114, 0.08);
}

.creativeSideInnerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 25px 40px;
  width: 100%;
}

.errorBorder {
  background-color: var(--red-1);
  border: 1px solid var(--red-5) !important;
  position: relative;
}

.creativeBackgroundIcon {
  position: absolute;
  top: 0;
  pointer-events: none;
}

.creativeIcon {
  position: relative;
  z-index: 1;
}

.creativeSideButton {
  min-height: 33px;
  padding: 8px 16px;
}

.creativeSideTitle {
  font-size: 18px;
  line-height: 1;
  margin: 46px 0 14px 0;
}

.creativeSideText {
  color: var(--secondary-4);
  line-height: 0.92;
}

.cardItemWrapper {
  max-width: 1000px;
  padding: 2.25rem;
  margin: -10px auto 0 auto;
  border: 1px solid var(--secondary-2);
  background-color: var(--secondary-1);
  position: relative;
  z-index: 10;
  border-radius: 12px;
}

.cardItemContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.drawerText {
  display: block;
  color: var(--secondary-4);
  margin-top: 0.5rem;
}

.btnContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 20px 0 16px 0;
}

.tabs {
  margin-top: 14px;
}

.tabs div[class*="ant-tabs-nav-list"],
.tabs div[class*="ant-tabs-tab"] {
  width: 100%;
}

.tabs div[class*="ant-tabs-tab"] {
  text-align: center;
}

.textarea,
.iframe {
  margin-top: 8px !important;
  border: 1px solid var(--secondary-2) !important;
}

.textarea {
  width: 100% !important;
  height: 100% !important;
  max-height: 450px !important;
  padding: 10px !important;
  resize: none;
}

.textareaScript {
  height: 382px !important;
}

.errorContainer {
  padding: 12px;
  border-radius: 10px;
  background: var(--red-2);
  margin: 14px auto -6px auto;

  & span {
    color: var(--red-5);
    line-height: 1.16;
  }
}

.errorInput {
  border: 1px solid var(--red-4) !important;
}

.iframe {
  border-radius: 10px;
  resize: vertical;
}
