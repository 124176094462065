.pageContainer {
  padding: var(--size-lg);
  background-color: var(--white);
  min-height: 85vh;
  margin-top: 60px;
}

.campaignsPage {
  padding-top: 0;
}

.academyPage {
  background-color: var(--secondary-1);
}