.warningRecommended {
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: var(--primary-4) !important;
  }
}

.allowedFileText {
  color: var(--secondary-4);
  margin-top: 0.5rem;
}

.maxFileSizeText {
  color: var(--secondary-4);
}
