.openBtn {
  width: 24px;
  height: 24px;
  border: var(--border-base);
  border-radius: var(--size-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  cursor: pointer;

  & svg {
    width: 12px;
    & path {
      fill: black;
    }
  }
}

.img {
  max-height: 60px;
  max-width: 200px;
}

.htmlInner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 160px;
  height: 60px;
  border: 1px solid var(--secondary-2);
  background: var(--secondary-1);

  & > svg {
    width: 20px;
    height: 20px;

    & path {
      fill: var(--secondary-4);
    }
  }
}

.htmlText {
  color: var(--secondary-4);
}
