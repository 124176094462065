.relative {
  position: relative;
}

.title {
  margin-bottom: 0 !important;
}

.pointer {
  cursor: pointer;
}

.radioText {
  color: var(--gray-4);
}

.formInput {
  position: absolute;
  bottom: 2.5em;
  left: 2.5em;
}

.column {
  flex-direction: column;
  flex-flow: column;
}

.radioGroup {
  flex-direction: row;
  margin-top: 1em;
}

.standard {
  width: 10.25rem;
  padding: 9px 15px;
  height: auto;
  border-radius: 6px 0px 0px 6px;
  margin-top: 0.75em;
}

.advanced {
  border-radius: 0 6px 6px 0;
}

.marginTop {
  margin-top: 25px;
}

.acos {
  position: absolute;
  bottom: 9.5rem;
  left: 2.5rem;
}

.footer {
  justify-content: flex-end;
  max-width: 1000px;
  margin: 1rem auto 2.25rem auto;
  gap: 1rem;
}

.cancelBtn {
  width: 7.875rem;
}

.okBtn {
  width: 8.5rem;
}

.divider {
  max-width: 1000px;
  min-width: 0;
  margin: 36px auto;
}

.select {
  width: 22rem !important;
  margin-top: var(--size-lg);
}

.selectLabel {
  color: var(--gray-4);
  margin-bottom: 10px;
  margin-top: var(--size-md);
  display: inline-block;
}

.selectContainer {
  margin-top: var(--size-md);
  border-top: var(--border-base);
}

.selectedSelect div {
  border: 1px solid var(--primary-4) !important;
  & span:nth-of-type(2) {
    color: var(--primary-4) !important;
    font-weight: 600;
  }
}

.lastBg {
  background-color: var(--secondary-8);
  border: 1px solid var(--secondary-2);
  margin-top: -0.75rem;
}

.selectedTitle {
  color: var(--secondary-6) !important;
}

.selectedText {
  color: var(--gray-3);
  margin: var(--size-xs) 0 var(--size-lg) 0;
  display: inline-block;
}

.selectedRow {
  width: 100%;
  background-color: var(--white);
  border-radius: var(--size-radius);
  border: var(--border-base);
  margin-bottom: 36px;
}

.listContainer {
  padding: var(--size-xxxs);
  border-right: var(--border-base);
}

.firstList {
  height: 14.35rem;
  overflow-y: auto;
}

.secondListFull {
  height: 18.875rem;
}

.secondList {
  padding: 1rem;
}

.secondListFull {
  padding: var(--size-xxxs);
  position: relative;
  min-height: 333px;
}

.listItemNavyText {
  color: var(--primary-6);
}

.listItem {
  padding: 1rem var(--size-s) 1rem 10px;
  border-bottom: 1px solid var(--secondary-1) !important;
}

.listItemText {
  color: var(--secondary-4);
}

.secondListItem {
  margin-bottom: 8px;
  padding: 10px var(--size-xxxs);
  background: var(--primary-2);
  border-radius: 8px;
  & span {
    color: var(--primary-6);
  }
  & svg {
    width: var(--size-xxs);
  }
}

.listFooter {
  position: absolute;
  bottom: 15px;
  left: 50%;
  width: 100%;
  text-align: center;
  border-top: var(--border-base);
  padding-top: 15px;
  transform: translateX(-50%);

  & span {
    color: var(--primary-4);
    width: 100%;
    cursor: pointer;
  }
}

.checkboxContainer {
  position: relative;
  &::before {
    content: "";
    width: calc(100% + 4.5rem);
    position: relative;
    display: block;
    margin: 0 0 36px -2.25rem;
    height: 1px;
    background-color: var(--secondary-2);
  }
  & label {
    min-width: 300px;
    margin-bottom: var(--size-xs);
    gap: 0.75rem;
    & span {
      font-family: "Poppins", sans-serif !important;
    }
  }
  & label:nth-of-type(13),
  & label:last-of-type {
    margin-bottom: 0;
  }
}

.metricContainer {
  background-color: var(--white);
  border: var(--border-base);
  border-radius: 8px;
  padding: 32px;
  margin-bottom: 36px;

  & > div {
    gap: 1rem;
    max-width: 650px;
  }
}

.reportType:hover + div {
  border-radius: 0;
}

.btnContainer {
  justify-content: flex-end;
}

.tagContainer {
  margin-top: 10px;
  padding: 1rem;
  background: var(--white);
  border: var(--border-base);
  border-radius: 8px;
  flex-wrap: wrap;
}

.datePicker {
  width: 100%;
  height: 50px;
  border-radius: var(--size-radius);
  & > div > input {
    height: 50px;
  }
}

.left {
  margin-bottom: 0;
}

.errorText {
  color: var(--red-4) !important;
  display: inline-block;
  margin-top: 6px;
}

.advertiser {
  margin-top: calc(4rem + 66px) !important;
}

.empty > div {
  padding: var(--size-xl) 0;

  & > div:first-of-type {
    margin-top: var(--size-xxxs);
  }
}

.clearBtn {
  min-height: 2.25rem;
}

.tags > div:first-of-type,
.sizes > div:first-of-type {
  margin-top: 2px;
}

.tags > div:first-of-type {
  min-height: 50px;
  padding: 4px 10px !important;
}

.tags div[class="ant-select-selection-search"] {
  -webkit-margin-start: 0;
  margin-inline-start: 0;
}

.dropdown {
  display: none;
}
