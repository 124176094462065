.info {
  color: var(--secondary-4);
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 14px;
}

.radioLabel {
  width: 100%;
  margin-bottom: 10px;
}

.radio {
  display: flex;
  align-items: center;
  max-width: 100%;
}

.video {
  width: 174px;
  height: 154px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 10px;
  background-color: var(--primary-1);
}

.videoBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  gap: 6px;
  background-color: var(--white);
  border-radius: 8px;
  border: 1px solid var(--secondary-2);
  box-shadow: 0px 2px 2px 0px rgba(30, 19, 92, 0.03);
}

.disabledVideo {
  pointer-events: none;
  cursor: not-allowed;
  
  & .videoBtn {
    background-color: var(--secondary-1) !important;

    & span {
      color: var(--secondary-3);
    }

    & svg path {
      fill: var(--secondary-3);
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding-left: 22px;
}

.contentText {
  color: var(--secondary-4);
  line-height: 1.5;
}

.contentBtn {
  font-size: 12px;
  min-height: 28px;
  max-width: max-content;
  padding: 0 8px;
  margin-top: 4px;
}

.soon {
  max-width: max-content;
  display: inline-flex;
  padding: 4px 6px;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-1);
  border-radius: 6px;
  color: var(--secondary-6);
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  margin-left: 10px;
}
