.pageWrapper {
  padding: 0;
  background-color: #0f0f0f;
  min-height: calc(100vh - 60px);
  margin-top: 60px;
}

.headerWrapper {
  padding: var(--size-lg);
  position: sticky;
  top: 60px;
  width: 100%;
  background-color: #1c1c1c;
  z-index: 12;
  border-bottom: 1px solid #454545;
}

.headerText {
  color: var(--white);
  line-height: 1;
}

.btnContainer {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}

.prev,
.next {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px;
  min-height: 42px;
  border: 1px solid #2c2c2c;
  background-color: #262626;
  cursor: pointer;
}

.prev,
.next {
  & svg {
    width: 10px;
  }

  & svg path {
    fill: var(--secondary-1);
  }
}

.prev {
  border-right: none;
  margin-left: auto;
}

.wrapper,
.emptyWrapper {
  padding: var(--size-lg);
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: calc(100vh - 211px);
}

.emptyWrapper {
  align-items: center;
}

.empty {
  width: 100%;
  padding: 0;
  margin-top: 24px;

  & > div > div:first-of-type {
    box-shadow: 0px 0px 0px 11.744px rgba(255, 255, 255, 0.05),
      0px 21.14px 5.872px 0px rgba(255, 255, 255, 0),
      0px 14.093px 5.872px 0px rgba(255, 255, 255, 0),
      0px 8.221px 4.698px 0px rgba(255, 255, 255, 0.01),
      0px 3.523px 3.523px 0px rgba(255, 255, 255, 0.02),
      0px 1.174px 2.349px 0px rgba(255, 255, 255, 0.02),
      0px 0px 0px 0px rgba(255, 255, 255, 0.02);
  }

  & div {
    & h5 {
      margin-top: 40px !important;
      color: var(--secondary-1) !important;
    }

    & span {
      margin-top: 24px;
      color: #7d7d7d;
    }
  }

  & svg path {
    fill: var(--white);
  }
}

.empty,
.empty div {
  background-color: transparent;
}

.searchContainer {
  width: 100%;
  min-height: 42px;
  display: flex;
}

.searchButton {
  padding: 0 14px;
  color: #e7e7e7;
  font-size: 12px;
  font-weight: 600;
  background-color: #2c2c2c;
  border: 1px solid #3c3c3c;
  transition: border-color 0.25s ease-in-out;
}

.searchInput {
  padding: 6px 14px;
  background-color: #1c1c1c;
  border: 1px solid #333;
  border-right: none !important;
  border-radius: 0;

  & input {
    color: #b4b4b4;
    background-color: #1c1c1c;

    &::placeholder {
      color: #828282;
      font-size: 12px;
      font-weight: 500;
      transition: color 0.25s ease-in-out;
    }
  }

  & svg {
    width: 16px;
    height: 16px;
    margin-right: 6px;

    & path {
      fill: #828282;
    }
  }
}

.searchContainer:hover {
  & .searchInput {
    border-color: #515151 !important;
  }

  & input::placeholder {
    color: #969696;
  }

  & svg path {
    fill: #969696;
  }

  & .searchButton {
    border-color: #515151;
  }
}

.searchContainer:focus-within {
  & .searchInput {
    border-color: #3d3d3d !important;
    background: #121212;
    outline: none;
    box-shadow: none !important;
  }

  & input {
    background: #121212;
  }

  & svg path {
    fill: #b4b4b4;
  }
}

.log + .log {
  margin-top: 14px;
}

.log pre {
  padding: 14px;
  background-color: #0f0f0f;
  border: 1px solid #323232;
  border-top: none;
  border-radius: 0;
  margin: 0;
}

.logHeader {
  height: 24px;
}

.logHeader,
.logHeaderText {
  display: flex;
  align-items: center;
}

.logHeaderText {
  width: 100%;
  height: 100%;
  padding: 0 8px;
  background-color: #2c2c2c !important;
  border: 1px solid #3c3c3c;
  border-right: none;

  & span {
    color: #afafaf;
  }

  & svg {
    width: 12px;
    height: 12px;
    margin-right: 6px;

    & path {
      fill: #afafaf;
    }
  }
}

.logTime {
  position: relative;
  text-transform: capitalize;
  margin-right: 16px;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: -8px;
    transform: translateY(-50%);
    width: 1px;
    height: 12px;
    background-color: #505050;
  }
}

.logMessage {
  max-width: 550px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.expandButton {
  display: flex;
  align-items: center;
  gap: 6px;
  height: 100%;
  background-color: #414141;
  border: 1px solid #5a5a5a;
  margin-left: auto;
  flex-shrink: 0;
  color: var(--secondary-8);
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
}

.disabled {
  cursor: not-allowed;
  background-color: #0f0f0f;

  & span {
    color: var(--gray-6);
  }

  & svg path {
    fill: var(--gray-6);
  }
}

.innerLogWrapper {
  transition: height 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
  overflow: hidden;
}
