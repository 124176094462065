.videoCard {
  display: none;
  flex-direction: column;
  width: 13.75rem;
  padding: 8px;
  border-radius: 12px;
  border: 1px solid var(--secondary-2);
  background-color: var(--white);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.04);

  & video {
    width: 100%;
    border-radius: 6px;
    max-height: 114px;
    object-fit: cover;
  }
}

.videoCard.submitted {
  display: flex;
}

.videoCardMeta {
  width: 100%;
  padding: 12px 6px 6px 6px;
  background-color: var(--secondary-1);
  border-radius: 6px;
  margin-top: -6px;
  justify-content: center;
}

.videoCardMetaText {
  font-size: 11px;
  color: var(--secondary-4);
  line-height: 1.27;
  text-transform: uppercase;
}

.videoNameContainer {
  width: 100%;
  padding: 12px 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2px;
  margin-top: auto;
  
  & svg path {
    fill: var(--secondary-3);
    transition: fill 0.3s ease-in-out;

    &:hover {
      fill: var(--primary-6);
    }
  }
}

.creativeName {
  color: var(--secondary-7);
  line-height: 1.33;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & strong {
    color: var(--secondary-6);
    font-weight: inherit;
  }
}

.videoCardFooter {
  display: flex;
  gap: 8px;
  padding: 10px 2px 2px 2px;
  position: relative;

  &::before {
    content: "";
    width: calc(100% + 20px);
    height: 1px;
    background-color: var(--secondary-2);
    position: absolute;
    top: 0;
    left: -10px;
  }
}

.videoCardIcon {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid var(--secondary-2);
  background-color: var(--white);
  box-shadow: 0px 2px 2px 0px rgba(30, 19, 92, 0.03);
  cursor: pointer;

  & svg {
    width: 14px;
    height: 14px;
  }
}

.editIcon path {
  fill: var(--secondary-7);
}

.deleteIcon {
  color: var(--red-5);
}

.languageActive {
  position: relative;
  border: 1.5px solid var(--primary-3);
  background: var(--primary-1);
  box-shadow: 0px 2px 15px 0px rgba(30, 19, 92, 0.03),
    0px 2px 2px 0px rgba(30, 19, 92, 0.03);

  & svg path:first-of-type {
    fill: var(--primary-4);
  }

  & svg path:last-of-type {
    fill: var(--primary-7);
  }
}

.languageContainer {
  position: absolute;
  top: -8px;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 6px;
  background: var(--primary-6);
  border-radius: 13px;
  min-width: 20px;
  width: max-content;

  & span {
    font-size: 11px;
    color: var(--white);
    line-height: 1;
  }
}
