.info {
  border: 1px solid var(--secondary-1);
  background: var(--secondary-1);
  margin-top: -26px;
  padding: 22px 12px 12px;
  margin-bottom: 1rem;
  border-radius: 0px 0px 12px 12px;
  flex-direction: column;
}

.infoTitle {
  color: var(--secondary-5) !important;
  letter-spacing: 0.44px;
  line-height: 1;
  margin-bottom: 6px;
}

.infoText {
  color: var(--secondary-4);
  line-height: 1.35;
}

.creativeUploadDragger {
  margin-top: 14px;
  max-height: 450px;
  border-radius: 12px !important;
}

.drawerText {
  display: block;
  color: var(--secondary-4);
  margin-top: 0.5rem;
}

.errorContainer {
  padding: 12px;
  border-radius: 10px;
  background: var(--red-2);
  margin: 14px auto -6px auto;

  & span {
    color: var(--red-5);
    line-height: 1.16;
  }
}

.errorInput {
  border: 1px solid var(--red-4) !important;
}

.tabs {
  margin-top: 14px;
}

.tabs div[class*="ant-tabs-nav-list"],
.tabs div[class*="ant-tabs-tab"] {
  width: 100%;
}

.tabs div[class*="ant-tabs-tab"] {
  text-align: center;
}

.textarea,
.iframe {
  margin-top: 8px !important;
  border: 1px solid var(--secondary-2) !important;
}

.textarea {
  width: 100% !important;
  height: 382px !important;
  max-height: 450px !important;
  padding: 10px !important;
  resize: none;
}

.iframe {
  border-radius: 10px;
  resize: vertical;
}
