.radioContainer {
  width: 100%;
}

.radioItem {
  width: 100% !important;
  margin-top: 12px;
  padding: 20px;
  border-radius: 12px;
  min-height: 80px;

  & > span:first-of-type {
    top: 9.5px;
  }

  & span:last-of-type {
    margin: auto 0;
  }
}

.radioInner {
  display: flex;
  gap: 18px;
  align-items: center;

  & article {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  & svg path {
    transition: fill 0.3s ease;
  }
}

.regularIcon path:first-of-type {
  fill: var(--secondary-1);
}

.regularIcon path:nth-child(2) {
  fill: var(--secondary-3);
}

.regularIcon path:last-of-type {
  fill: var(--secondary-7);
}

.selectedIcon path:first-of-type {
  fill: var(--primary-3);
}

.selectedIcon path:nth-child(2),
.selectedIcon path:last-of-type {
  fill: var(--primary-4);
}

.drawerRadioText {
  color: var(--secondary-4);
  line-height: 1;
}
