.justifyContent {
  width: 100%;
  justify-content: space-between;
}

.col {
  flex-direction: column;
}

.title {
  margin-bottom: 0.5rem !important;
}

.text {
  color: var(--secondary-5);
  display: block;
  max-width: 400px;
  line-height: 24px;
}

.title,
.download {
  color: var(--primary-4) !important;
}

.container {
  padding: 36px;
  background-color: var(--white);
  border: 1px solid var(--secondary-3);
  border-radius: 12px;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.skeletonContainer {
  align-items: center;
}

.container:last-of-type {
  margin-bottom: 0;
}

.secondaryText {
  color: var(--secondary-7);
}

.input,
.select {
  width: 18.625rem !important;
}

.facebookButton {
  width: 100%;
  max-width: 298px;
  background-color: #1877f2;
  color: var(--white);
  padding: 0.5rem 1rem;
  border-radius: 14px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.facebookText {
  flex-grow: 1;
}

.facebookIcon {
  width: max-content;
  margin-right: auto;
}

.nameContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
  border: 1px solid var(--secondary-2);
  border-radius: 14px;
  max-width: 298px;
}

.name {
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  padding: 14px;
  background-color: var(--secondary-1);
  color: var(--secondary-6);
  margin: 0;
  border-bottom: 1px solid var(--secondary-2);
  border-radius: 14px 14px 0 0;
}

.buttonContainer {
  display: flex;
  gap: 0.25rem;
  width: 100%;
  justify-content: space-between;
  padding: 12px 10px;
}

.reauthenticateButton,
.revokeButton {
  min-height: 28px;
  width: 50%;
}

.revokeButton,
.revokeButton:hover {
  color: var(--red-5);
}

.form {
  width: 100%;
  margin-top: 1rem;
}

.select {
  width: 100%;
}

.innerContainer {
  width: 100%;
  & h5 {
    font-size: 18px;
    line-height: 1;
  }

  & .text {
    max-width: unset;
  }

  &::after {
    content: "";
    display: block;
    width: calc(100% + 72px);
    height: 1px;
    background-color: var(--secondary-2);
    margin: 32px 0 32px -36px;
  }
}

.pendingContainer {
  min-height: 370px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  gap: 1.25rem;
  border-radius: 1rem;
  background-color: var(--white);
  box-shadow: 0px 18px 5px 0px rgba(32, 43, 54, 0),
    0px 12px 5px 0px rgba(32, 43, 54, 0), 0px 7px 4px 0px rgba(32, 43, 54, 0.01),
    0px 3px 3px 0px rgba(32, 43, 54, 0.02),
    0px 1px 2px 0px rgba(32, 43, 54, 0.02),
    0px 0px 0px 0px rgba(32, 43, 54, 0.02);
  padding: clamp(1rem, 3.125rem, 20%);
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 86px;
  height: 86px;
  margin-bottom: 0.625rem;
  border-radius: 50%;
  background-color: var(--white);
  box-shadow: 0px 0px 0px 8px rgba(0, 0, 0, 0.03),
    0px 18px 5px 0px rgba(32, 43, 54, 0), 0px 12px 5px 0px rgba(32, 43, 54, 0),
    0px 7px 4px 0px rgba(32, 43, 54, 0.01),
    0px 3px 3px 0px rgba(32, 43, 54, 0.02),
    0px 1px 2px 0px rgba(32, 43, 54, 0.02),
    0px 0px 0px 0px rgba(32, 43, 54, 0.02);
}

.loadingIcon {
  font-size: 2.875rem;
  color: var(--secondary-6);
}

.pendingTitle {
  font-size: 1.125rem;
  color: var(--secondary-6);
  line-height: 1;
}

.pendingText {
  max-width: 400px;
  color: var(--secondary-4);
  line-height: 1.6;
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .nameContainer {
    width: 100%;
  }
}
