.form {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  & .textContainer,
  form {
    width: 90%;
    max-width: 450px;
  }

  & h1 {
    color: var(--primary-6);
    font-weight: 700;
    font-size: 28px;
    line-height: 1;
  }

  & .textContainer p {
    font-weight: 500;
    font-size: 14px;
    color: var(--secondary-4);
    margin-bottom: 0;
  }

  & input::placeholder,
  a {
    font-family: "Poppins", sans-serif !important;
    font-weight: 500;
    font-size: 14px;
  }

  & form {
    border: 1px solid var(--secondary-2);
    padding: 40px;
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  }
}

.textContainer {
  align-items: flex-start;
  gap: 30px;
  margin-bottom: 40px;
}

.iconContainer {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--secondary-1);
  justify-content: center;

  & svg {
    width: 2rem !important;
  }
}

.btnItem {
  margin-bottom: 0 !important;
}

.privacy label span,
.text {
  color: var(--secondary-4);
  position: relative;
}

.textSecond {
  color: var(--secondary-4);
}

.text::after {
  content: "";
  height: 80%;
  width: 2px;
  background-color: var(--secondary-3);
  position: absolute;
  border-radius: 10px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 1rem;
}

.formItem {
  margin-bottom: 10px;
}

.formItem input:not([id="password"]),
.formItem > div > div > div > span {
  border: var(--border-base);
  border-radius: var(--size-radius);
  padding: 8px 14px;
  height: 48px;
  &:hover {
    border: 1px solid var(--primary-4) !important;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.03),
      0px 2px 10px rgba(0, 0, 0, 0.03);
  }
}

.formItem input:focus:not([id="password"]) {
  border: 1px solid var(--primary-4) !important;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.03), 0px 2px 10px rgba(0, 0, 0, 0.03);
}

.formItem div:last-child {
  margin-bottom: 5px;
}

.btn {
  width: 100%;
}

.subContainer {
  margin-top: 30px;
}

.subContainer,
.privacy label {
  display: flex;
  align-items: flex-start;
}

.privacy label {
  gap: 10px;
}

.top {
  position: absolute;
  width: 50%;
  height: 6px;
  background-color: var(--primary-4);
  top: 0;
  right: 0;
}

.notSafe {
  color: var(--yellow-6);
}

.safe {
  color: var(--green-9);
}

.soSafe {
  color: var(--green-10);
}

.login {
  display: flex;
  gap: 2rem;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;

  & a {
    color: var(--primary-4);
    text-decoration: underline;
  }
}

.afterSignupWrapper {
  flex-direction: column;
  align-items: flex-start;
  max-width: 435px;
  margin: 0 auto;

  & h2 {
    color: var(--primary-6) !important;
    font-size: 28px !important;
    margin-bottom: 42px;
  }

  & article {
    border: 1px solid var(--secondary-2);
    padding: 40px;
    border-radius: 16px;
    flex-direction: column;
    gap: 0;
  }
}

.icon,
.afterSignupWrapper {
  justify-content: center;
}

.icon {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  & svg {
    width: 1.75rem;
    height: 1.75rem;
  }
}

.icon.success {
  background-color: var(--green-1);
}

.icon.error {
  background-color: var(--red-1);

  & svg path {
    fill: var(--red-5);
  }
}

.afterSignupTitle {
  text-align: center;
  margin: 1.875rem 0 1.375rem;
}

.afterSignupText {
  text-align: center;
  line-height: 1.7;
  margin-bottom: 1.875rem;
}

.afterSignupText,
.link,
.underline {
  color: var(--secondary-4);
}

.link {
  margin-top: 1.875rem;
}

.underline {
  text-decoration: underline;
  padding-right: 4px;
}

.underlineEnglish {
  padding-left: 4px;
}

.checkbox {
  font-size: 12px !important;
  font-family: "Poppins", sans-serif !important;
  margin-left: 0 !important;
  line-height: 20px;

  & a {
    font-size: inherit;
    color: inherit;
  }
}

.checkbox + .checkbox {
  margin-top: 0.5rem;
}
