.secondaryTitle,
.title {
  margin-bottom: 0.5rem !important;
}

.secondaryTitle {
  color: var(--primary-6) !important;
  font-size: 18px !important;
}

.text,
.categoryText {
  color: var(--secondary-5);
}

.secondaryText {
  color: var(--secondary-4);
}

.title {
  color: var(--primary-4) !important;
  line-height: 16px !important;
}

.container {
  padding: 36px;
  background-color: var(--white);
  border: 1px solid var(--secondary-2);
  border-radius: 12px;
  margin-bottom: var(--size-lg);

  & > div:not(:last-child) {
    margin-bottom: 36px;
  }

  & .link {
    font-weight: 500;
    font-size: 14px;
    color: var(--primary-4);
    text-decoration: underline;
    margin-top: 0.5rem;
    display: block;
  }
}

.flex,
.couponContainer,
.inputFlex {
  align-items: flex-start;
}

.container,
.justifyContent {
  justify-content: space-between;
}

.flex,
.inputFlex {
  display: flex;
  justify-content: flex-start;
}

.flex {
  gap: 4rem;
}

.inputFlex {
  justify-content: space-between;
}

.text,
.secondaryText,
.relevance input {
  width: 400px !important;
}

.text,
.secondaryText,
.categoryText {
  line-height: 24px;
  display: block;
}

.warning {
  margin-top: 1rem;
  white-space: break-spaces;
}

.couponContainer input,
.input,
.select {
  width: 300px !important;
}

.select,
.dropdown {
  font-size: 12px;

  & div {
    font-size: inherit;
  }
}

.modal button[class="ant-modal-close"] {
  top: 1rem;
  right: 24px;
  & span {
    width: 30px;
  }
}

.modalText {
  color: var(--red-5);
}

.suffix {
  position: absolute;
  top: 50%;
  right: var(--size-xs);
  transform: translateY(-100%);
  color: var(--secondary-7);
}

.advertiserBtn {
  width: 18.75rem;
  height: 42px;
  justify-content: center;
  gap: 9px;
  font-weight: 500;

  & span {
    color: var(--secondary-7);
    line-height: 1;
  }

  & svg path {
    fill: var(--secondary-7);
  }
}

.lengthText {
  color: var(--secondary-5);
  display: inline-block;
  margin-top: 10px;
}

.advertisersList {
  width: 100%;
  height: 160px;
  overflow-y: auto;
  overflow-x: hidden;
}

.advertiserItem {
  display: block;
  padding: 10px;
  margin: 10px 0;
  border-radius: 8px;
  cursor: pointer;
}

.itemSelected {
  background-color: var(--primary-2);
  pointer-events: none;
}

.tagContainer {
  flex-wrap: wrap;
  gap: 10px;
  max-height: 100px;
  overflow-y: auto;
}

.tag {
  background-color: var(--secondary-3);
  border-radius: 8px;
  padding: 6px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
  color: var(--primary-6);
  line-height: 1;
  margin-right: 0;

  & svg path {
    fill: var(--primary-6);
  }
}

.tabHeader {
  width: calc(100% - 230px);
  position: fixed;
  background-color: var(--white);
  height: 52px;
  border-bottom: var(--border-base);
  box-shadow: 0px 2px 15px rgba(30, 19, 92, 0.03),
    0px 2px 2px rgba(30, 19, 92, 0.03);
  top: 60px;
  z-index: 12;
}

.tabs {
  margin-top: 5.75rem;

  & form {
    margin-top: 82px;
  }
}

.tabs > div:first-child {
  max-width: 820px;
}

.tabs.bidTab > div:first-child {
  max-width: 1100px;
}

.tabs > div:first-child {
  background-color: transparent;
  padding: 0;
  border: none;
  height: 52px;
  margin-bottom: 36px;
  position: fixed;
  top: 60px;
  z-index: 12;
  width: 100%;

  & div[class="ant-tabs-nav-list"] {
    width: 100%;
    justify-content: space-between;
  }

  & > div,
  & div[class="ant-tabs-tab ant-tabs-tab-active"] {
    box-shadow: none;
    background-color: transparent;
  }

  & div[class="ant-tabs-tab"],
  div[class="ant-tabs-tab ant-tabs-tab-active"] {
    margin-bottom: 0 !important;
    width: 12.8125rem;
    justify-content: center;
    border-radius: 0;
  }

  & div[class="ant-tabs-tab"] {
    border-bottom: 2px solid transparent;
  }

  div[class="ant-tabs-tab ant-tabs-tab-active"] {
    border-bottom: 2px solid var(--primary-4);
    transition: border 0.2s linear;
  }
}

.infoTag {
  margin-top: 1rem;
  padding: 14px 12px;
  width: max-content;
}

.bidContainer > div {
  max-width: 1100px !important;
}

.hidePrefix span[class="ant-input-prefix"] {
  display: none;
}

.noMarginFormItem {
  margin-bottom: 0 !important;
}

.formatTitleContainer {
  padding-bottom: 36px;

  &::after {
    content: "";
    display: block;
    width: calc(100% + 72px);
    height: 1px;
    background-color: var(--secondary-2);
    margin: 36px 0 0 -36px;
  }
}

.formatText {
  width: 100% !important;
}

.btnContainer {
  width: 100%;
  height: 34px;
  max-width: 298px;
  padding: 3px;
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 10px;
  background-color: var(--secondary-1);

  & button {
    width: 100%;
    height: 28px;
    background-color: transparent;
    color: var(--secondary-4);
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    transition: all 0.2s linear;
  }

  & .activeButton {
    color: var(--primary-4);
    border-radius: 10px;
    background-color: var(--white);
    box-shadow: 0px 4px 8px 0px rgba(52, 42, 114, 0.08),
      0px 1px 2px 0px rgba(52, 42, 114, 0.08);
  }
}

.widgetTitleContainer {
  max-width: 400px;
}
