.hidePrefix span[class="ant-input-prefix"] {
  display: none;
}

.redirectFormItem {
  margin-bottom: 0;
  flex-direction: column;
}

.textarea {
  min-height: 140px !important;
  resize: none;
}

.previewContainer {
  width: 50%;
}

.previewCard {
  background: var(--white);
  border-radius: 10px;
  border: 1px solid var(--secondary-2);
}

.previewHeader {
  background-color: var(--secondary-1);
  padding: 10px 12px;
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid var(--secondary-2);
}

.previewTitle {
  color: var(--secondary-6);
  line-height: 0.85;
}

.field,
.fieldWithImage {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-bottom: 1px solid var(--secondary-2);

  &:last-child {
    margin-bottom: 0;
  }
}

.fieldWithImage {
  flex-direction: row;
  justify-content: space-between;
}

.fieldLabelContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.fieldLabel {
  color: var(--secondary-4);
  line-height: 1.15;
}

.fieldValue {
  color: var(--secondary-7);
  line-height: 1.15;
}

.fieldLink {
  color: var(--primary-4);
  font-size: 12px;
  font-weight: 500;
  text-decoration: underline !important;

  &:hover {
    text-decoration: underline;
  }
}

.thumbnailImage {
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 6px;
}

.previewFooter {
  background-color: var(--secondary-1);
  padding: 10px 12px;
  border-radius: 0 0 10px 10px;
}

.editButton {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: var(--white);
  border: 1px solid var(--secondary-2);
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0px 17px 5px 0px rgba(0, 0, 0, 0),
      0px 11px 4px 0px rgba(0, 0, 0, 0), 0px 6px 4px 0px rgba(0, 0, 0, 0.02),
      0px 3px 3px 0px rgba(0, 0, 0, 0.03), 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
  }

  &:focus {
    box-shadow: 0px 2px 2px 0px rgba(30, 19, 92, 0.03),
      0px 0px 0px 3px var(--white), 0px 0px 0px 4px var(--secondary-1);
  }
}

.drawerTitle {
  margin-bottom: 4px !important;
}

.drawerTitle:not(:first-of-type) {
  margin-top: 16px;
}

.drawerText {
  color: var(--secondary-4);
  display: inline-block;
  line-height: 1.5;
  margin-bottom: 14px;
}

.drawerInput {
  width: 100% !important;
}
