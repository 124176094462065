.modal {
  min-width: 36rem !important;
  width: max-content !important;

  & img,
  video {
    width: 100%;
    max-width: max-content;
    display: flex;
    margin: 0 auto;
    max-height: 60vh;
    border-radius: 12px;
  }

  & iframe {
    max-width: 100%;
    max-height: 60vh;
    border-radius: 12px;
    border: none;
  }
}

.modal div[class="ant-modal-header"] {
  border-radius: 1.25rem 1.25rem 0 0;
  padding: 18px 18px 2px 18px;
  border: none;
  position: relative;

  & h4 {
    font-size: 18px;
  }
}

.modal div[class="ant-modal-body"] {
  padding: 18px;
}

.modal div[class="ant-modal-footer"] {
  padding: 0 18px 18px 18px;
  justify-content: flex-end;
}

.modalBtn {
  width: 100%;
}

.container {
  width: max-content;
  max-width: 100%;
  padding: 10px;
  border-radius: 14px;
  margin: 0 auto;
}

.withURL {
  border: 1px solid var(--secondary-2);
  background-color: var(--white);
}

.creativeNameContainer {
  width: 100%;
  margin-top: 22px;
}

.creativeName {
  display: block;
  color: var(--black);
  line-height: 1.25;

  & strong {
    color: var(--secondary-5);
    font-weight: inherit;
  }
}
