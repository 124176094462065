.tab {
  border-radius: var(--size-radius);
  border: var(--border-base);
  margin-top: 20px;
}

.line {
  padding: 1rem;
}

.line:not(:last-of-type) {
  border-bottom: var(--border-base);
}

.spaceBetween {
  justify-content: space-between;
}

.item {
  color: var(--secondary-4);
}

.icon {
  cursor: pointer;
}

.input {
  width: 100% !important;
}

.title {
  color: var(--secondary-7) !important;
  margin-bottom: 1rem !important;
}

.popover > div > div:last-of-type {
  border-radius: 8px;
  border: none;

  & > div {
    padding: 0.15rem !important;
    width: 160px !important;
  }
}

.text {
  gap: 1em;
  padding: 0.7em 1em;
  margin-bottom: 0.1em;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.25s ease-in;
  width: max-content;
  color: var(--primary-6);

  &:hover {
    background: var(--primary-2);
  }
}

.link {
  color: var(--primary-6) !important;
  &:hover {
    color: var(--primary-6) !important;
  }
}

.invoiceNo {
  display: inline-block;
  width: 150px;
}

.amount {
  display: flex;
}

.red {
  color: var(--red-7);
}

.green {
  color: var(--green-7);
}

.tooltip span[class*="ant-tooltip-arrow"] {
  border: 1px solid var(--red-3);

  &::before {
    top: -13.313709px;
    left: -13.313709px;
  }
}

.tooltipTextContainer span {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.tooltipTitle {
  color: var(--red-5);
  line-height: 0.85;
  margin-bottom: 0.5rem;
  text-align: center;
}

.tooltipDesc {
  color: var(--secondary-6);
  line-height: 1.4;
}

.refundButton {
  min-width: 140px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1.5px solid var(--red-5);
  background-color: var(--white);
  color: var(--red-5);
  font-weight: 600;

  & span svg {
    font-size: 1.125rem;
  }

  &:hover,
  &:focus {
    border: 1.5px solid var(--red-5);
    background-color: var(--red-1);
    color: var(--red-5);

    & span svg path {
      fill: var(--red-5);
    }
  }
}

.modalInnerWarning {
  display: flex;
  gap: 10px;
  border-radius: 10px;
  padding: 12px;
  background-color: var(--red-2);
  margin-bottom: 20px;

  & > svg {
    flex-shrink: 0;
  }
}

.modalInnerTitle {
  color: var(--red-5) !important;
  font-size: 12px !important;
  line-height: 1.15 !important;
}

.modalInnerDescription {
  display: inline-block;
  color: var(--secondary-5);
  margin-top: 4px;
}

.formItem:not(:last-of-type) {
  margin-bottom: 12px;
}

.flex {
  display: flex;
  gap: 12px;
  margin-bottom: 12px;
}

.formRow span[class*="number-input"] {
  min-width: 100% !important;
  max-width: 236px;
  margin-top: 0;
}

.textarea {
  width: 100% !important;
  min-height: 100px !important;
  resize: none;
}

.hidePrefix span[class="ant-input-prefix"] {
  display: none;
}

.hidePrefix,
.showPrefix {
  & span:not(span[class="ant-input-prefix"]) {
    width: 23rem;
  }
}

.summaryContainer {
  position: relative;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  border-radius: 12px;
  background-color: var(--secondary-1);
  margin-bottom: 14px;

  &::after {
    content: "";
    position: absolute;
    display: block;
    width: 1px;
    height: 50%;
    background-color: var(--secondary-3);
    left: calc(50% - 16px);
  }
}

.summaryItem {
  display: flex;
  flex: 1;
  gap: 14px;

  & > div {
    display: flex;
    flex-direction: column;
  }

  & svg {
    width: 30px;
    height: 30px;

    & path {
      fill: var(--secondary-7);
    }
  }
}

.summaryItemTitle {
  color: var(--secondary-6);
}

.summaryItemText {
  color: var(--secondary-7);
}

.checklistContainer {
  padding: 1rem;
  border-radius: 13px;
  border: 1px solid var(--secondary-2);
}

.checkboxItem {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 0 !important;

  & > span:last-of-type {
    color: var(--secondary-5);
    font-weight: 500;
    line-height: 1.3;
  }
}

.checkboxItem:not(:last-of-type) {
  margin-bottom: 24px;
}
