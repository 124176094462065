.relative {
  position: relative;
}

.advertiser {
  margin-top: calc(4rem + 33px) !important;
}

.currentSubtitle {
  color: var(--primary-4);
  font-size: var(--size-xxs);
}

.finishedSubtitle,
.success {
  color: var(--green-10);
}

.error {
  color: var(--red-4) !important;
}

.errorBorder {
  background-color: var(--red-1);
  border: 1px solid var(--red-5) !important;
  position: relative;
}

.errorIcon {
  right: -86%;
}

.errorIcon,
.errorIconTotal {
  position: relative;
  top: -10px;
  pointer-events: none;
}

.errorIconTotal {
  right: -78%;
}

.radioText {
  color: var(--secondary-4);
  max-width: 349px;
  display: inline-block;
  line-height: 1.5;
}

.column {
  flex-direction: column;
  flex-flow: column;
}

.radioGroup {
  flex-direction: row;
  margin-top: 1em;
}

.footerDivider,
.footer {
  max-width: 1000px;
}

.footerDivider {
  min-width: 0;
  margin: 36px auto;
}

.footer {
  justify-content: space-between;
  margin: 0 auto 36px auto;
}

.cancelBtn {
  width: 5.625rem;
}

.prevBtn {
  width: 7.625rem;
  margin-left: auto;
  justify-content: center;
}

.nextBtn {
  width: 11.375rem;
}

.empty {
  max-width: calc(1000px - 5rem);
  margin: 1rem auto;
  background-color: var(--primary-1);

  & > div > div:last-of-type {
    max-width: 360px;
  }
}

.wrapper {
  max-width: 1000px;
  justify-content: space-between;
  margin: 0 auto;
  border-radius: 12px;
  padding: 2.25rem;

  &:focus-within > div:first-of-type,
  &:hover > div:first-of-type {
    background-color: var(--secondary-1);
    border-radius: 12px 12px 0 0;
  }
  &:focus-within > div:last-of-type,
  &:hover > div:last-of-type {
    border-radius: 0 0 12px 12px;
  }
}

.fullHeight {
  min-height: 56vh;
}

//Navbar
.nav {
  justify-content: space-between;
  padding: 0 0 0 var(--size-lg);
  border-bottom: var(--border-base);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.03), 0px 2px 10px rgba(0, 0, 0, 0.03);
  gap: 0;
  position: sticky;
  top: 0;
  background-color: var(--white);
  z-index: 100;
}

.close {
  width: 35px;
  height: 35px;
  background-color: var(--primary-2);
  border-radius: var(--size-radius);
  justify-content: center;
}

.close,
.close svg,
.backBtn,
.pointer {
  cursor: pointer;
}

.nav,
.backBtn,
.btn {
  height: 82px;
}

.name,
.title {
  margin-bottom: 0 !important;
}

.name {
  font-size: 19px !important;
}

.text {
  color: var(--secondary-4);
  gap: 8px;
}

.container {
  margin-left: auto;
  width: 100%;
}

.small {
  max-width: 15rem;
}

.wide {
  max-width: 35rem;
}

.btn {
  width: 250px;
  border-radius: 0;
  text-align: left;
}

.span {
  display: block;
}

.span:last-of-type:not(:first-of-type) {
  font-weight: 400;
  color: var(--secondary-2);
}

.advertiserNav {
  top: 66px;
}

.summaryContainer {
  border: 1px solid var(--secondary-2);
  border-radius: 0.75rem;

  & h6:first-of-type {
    border-radius: 0.625rem 0.625rem 0 0;
  }

  & h6 {
    padding: 0.625rem 0.75rem;
    background: var(--secondary-1);
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1;
    color: var(--secondary-4);
    margin-bottom: 0;
  }
  //Rows:
  & > article {
    gap: 0;
    padding: 0.875rem;
    // cols in rows:
    & > div {
      width: 100%;
    }

    & > div:nth-child(2) {
      border-left: 1px solid var(--secondary-2);
    }
  }

  & .summaryColTitle,
  .summaryColText {
    font-size: 0.8125rem;
    font-weight: 500;
  }

  & .summaryColTitle {
    line-height: 1.05;
    color: var(--secondary-5);
    margin-bottom: 0.625rem;
  }

  & .summaryColText {
    line-height: 1.25;
    color: var(--secondary-7);
    margin-bottom: 0;
  }

  & .summaryColItem {
    margin-right: 0.5rem;
  }

  & > article:not(:last-of-type) {
    border-bottom: 1px solid var(--secondary-2);
  }
}

.summaryImg {
  width: 30px;
  height: 30px;
  border-radius: 8px;
  object-fit: cover;
}
