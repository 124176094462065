.drawer > div:last-of-type div {
  background-color: #0f0f0f;
}

.drawer div[class="ant-drawer-header"] {
  border-bottom: 1px solid #2c2c2c;
}

.curlWarning {
  padding: 18px 24px;
  background-color: rgba(255, 25, 93, 0.15);
  /* position: sticky;
  top: -24px; */
  margin: -24px 0 0 -24px;
  width: calc(100% + var(--size-lg) * 2);
  display: flex;
  align-items: center;
  gap: 14px;
  /* z-index: 10; */

  & span {
    color: #ff195d;
  }

  & svg path {
    fill: #ff195d;
  }
}

.curlWrapper {
  position: relative;
  border: 1px solid #323232;
  max-height: 384px;
  overflow: scroll;
  margin-top: 24px;
}

.curlHeader {
  position: sticky;
  top: 0;
  padding: 16px;
  background-color: #0f0f0f;
  border-bottom: 1px solid #323232;

  & span {
    color: var(--secondary-2);
  }
}

.curlInner {
  padding: 14px;
}

.curl {
  background-color: transparent !important;

  & span[class="hljs-comment"] {
    color: #969896;
  }

  & span[class="hljs-string"] {
    color: #b9ca4a;
  }
}

.button {
  width: 100%;
  background-color: #222;
  min-height: 54px;
  gap: 8px;
  border: 1px solid #3c3c3c;
  border-radius: 0;
  color: var(--white);
  font-size: 16px;
  font-weight: 600;
  margin-top: 12px;

  &:hover,
  &:focus,
  &:active,
  &:disabled,
  &:disabled:hover {
    border: 1px solid #3c3c3c;
    background-color: #222 !important;
    color: var(--white);
    box-shadow: none !important;

    & svg path {
      fill: var(--white);
    }
  }
}

.playIcon {
  width: 14px;
  height: 14px;
}
