.displayCard {
  display: none;
  flex-direction: column;
  width: 13.75rem;
  padding: 8px;
  border-radius: 12px;
  border: 1px solid var(--secondary-2);
  background-color: var(--white);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.04);
}

.displayCard.submitted {
  display: flex;
}

.imgContainer {
  position: relative;
  z-index: 1;
  display: flex;
  border-radius: 6px;

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background-color: rgba(50, 60, 70, 0.25);
    width: 100%;
    height: 100%;
    backdrop-filter: blur(1px);
    display: block;
    border-radius: 6px;
    overflow: hidden;
  }

  & img,
  iframe {
    position: relative;
    width: 100%;
    border-radius: 6px;
    max-height: 114px;
    object-fit: cover;
  }

  & iframe {
    border: none;

    & img {
      width: 100%;
    }
  }
}

.displayButton {
  position: absolute;
  right: 6px;
  bottom: 6px;
  z-index: 10;
  height: 24px;
  padding: 0 8px;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.25) 0%,
      rgba(255, 255, 255, 0.25) 100%
    ),
    linear-gradient(
      324deg,
      rgba(255, 255, 255, 0.3) 9.92%,
      rgba(255, 255, 255, 0.15) 95.32%
    );
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;

  & span {
    color: var(--white);
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.1);
    line-height: 1.15;
    text-transform: uppercase;
  }

  & svg {
    width: 12px;
    height: 12px;
    filter: drop-shadow(0px 1.636px 3.273px rgba(0, 0, 0, 0.1));

    & path {
      fill: var(--white);
    }
  }
}

.displayCardMeta {
  width: 100%;
  padding: 12px 6px 6px 6px;
  background-color: var(--secondary-1);
  border-radius: 6px;
  margin-top: -6px;
  justify-content: center;
  gap: 6px;
}

.displayCardMetaText {
  font-size: 11px;
  color: var(--secondary-4);
  line-height: 1;
  text-transform: uppercase;
}

.displayNameContainer {
  width: 100%;
  padding: 12px 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2px;
  margin-top: auto;

  & svg path {
    fill: var(--secondary-3);
    transition: fill 0.3s ease-in-out;

    &:hover {
      fill: var(--primary-6);
    }
  }
}

.creativeName {
  color: var(--secondary-7);
  line-height: 1.33;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & strong {
    color: var(--secondary-6);
    font-weight: inherit;
  }
}

.displayCardFooter {
  display: flex;
  gap: 8px;
  padding: 10px 2px 2px 2px;
  position: relative;

  &::before {
    content: "";
    width: calc(100% + 20px);
    height: 1px;
    background-color: var(--secondary-2);
    position: absolute;
    top: 0;
    left: -10px;
  }
}

.displayCardIcon {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid var(--secondary-2);
  background-color: var(--white);
  box-shadow: 0px 2px 2px 0px rgba(30, 19, 92, 0.03);
  cursor: pointer;

  & svg {
    width: 14px;
    height: 14px;
  }
}

.editIcon path {
  fill: var(--secondary-7);
}

.deleteIcon {
  color: var(--red-5);
}

.languageActive {
  position: relative;
  border: 1.5px solid var(--primary-3);
  background: var(--primary-1);
  box-shadow: 0px 2px 15px 0px rgba(30, 19, 92, 0.03),
    0px 2px 2px 0px rgba(30, 19, 92, 0.03);

  & svg path:first-of-type {
    fill: var(--primary-4);
  }

  & svg path:last-of-type {
    fill: var(--primary-7);
  }
}

.languageContainer {
  position: absolute;
  top: -8px;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 6px;
  background: var(--primary-6);
  border-radius: 13px;
  min-width: 20px;
  width: max-content;

  & span {
    font-size: 11px;
    color: var(--white);
    line-height: 1;
  }
}
