.container > div {
  width: 100%;
}

.marginLeft {
  margin-left: 0.5rem;
}

.modal {
  width: 42rem !important;
}

.modal div[class="ant-modal-header"] {
  border-radius: 1.25rem 1.25rem 0 0;
  padding: 1.125rem 1.125rem 0 1.125rem;
  border: none;
  position: relative;
}

.modal div[class="ant-modal-footer"] {
  padding: 0 1.125rem 1.125rem 1.125rem;
}

.title {
  font-size: 18px !important;
  color: var(--primary-6) !important;
  line-height: 1 !important;
  margin-bottom: 2px !important;
}

.sub {
  color: var(--secondary-4);
}

.advertiserModal .sub {
  display: inline-block;
  line-height: 1;
  margin-bottom: 20px;
}

.advertiserModal div[class="ant-modal-body"] {
  padding: 10px;
}

.radioGroup {
  width: 100%;
}

.radio,
.card {
  padding: 10px;
  margin: 0 0 10px 0;
  width: 100%;
  align-items: flex-start;
  border: 1px solid var(--secondary-2);
  border-radius: 10px;
  box-shadow: 0px 9px 2px 0px rgba(0, 0, 0, 0), 0px 6px 2px 0px rgba(0, 0, 0, 0),
    0px 3px 2px 0px rgba(0, 0, 0, 0.02), 0px 1px 1px 0px rgba(0, 0, 0, 0.03),
    0px 0px 1px 0px rgba(0, 0, 0, 0.03);
}

.row,
.card {
  align-items: center;
  gap: 18px;
  flex-wrap: nowrap;
}

.radio svg rect,
.radio svg path {
  transition: fill 0.3s ease, stroke 0.3s ease-in-out;
}

.radio[class*="ant-radio-wrapper-checked"] {
  & .radioTitle {
    color: var(--primary-4) !important;
  }

  & svg rect[fill="var(--secondary-1)"] {
    fill: var(--primary-2);
  }

  & svg rect[stroke="var(--secondary-2)"] {
    stroke: var(--primary-3);
  }

  & svg path[fill="var(--secondary-3)"] {
    fill: var(--primary-3);
  }

  & svg rect[fill="var(--secondary-4)"] {
    fill: var(--primary-4);
  }
}

.imgContainer {
  display: flex;
}

.textContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.row .textContainer {
  max-width: 382px;
}

.radio span:last-of-type {
  padding: 0;
}

.radio > span:first-of-type {
  order: 1;
  margin: 10px 10px 0 auto;
}

.cardTitle,
.radioTitle {
  color: var(--secondary-5) !important;
  font-size: var(--size-xs) !important;
  margin-bottom: 4px !important;
}

.cardText,
.radioText {
  color: var(--secondary-4);
}

.tag {
  background-color: var(--secondary-1);
  color: var(--secondary-6);
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 6px;
  border: none;
  margin-left: 10px;
}

.btnContainer {
  width: 100%;
  justify-content: space-between;
}

.cancelBtn {
  width: 10rem;
}

.okBtn {
  width: 28.3rem;
}

.advertiserDropdown {
  display: none;
}

.showDropdown {
  display: block;
}

.intro {
  width: 27rem;
  max-width: unset;
  border-radius: 12px;

  & > div:nth-of-type(2) > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  & h3 {
    color: var(--primary-6);
    font-weight: 600;
    line-height: 1;
    margin-bottom: 1rem;
    padding-top: 30px;
  }

  & p {
    color: var(--secondary-5);
    line-height: 1.5;
    font-family: "Poppins", sans-serif !important;
    margin-bottom: 0;
  }
}

.menuHighlight {
  left: 0 !important;
  width: 76px !important;
  height: calc(100% - 62px) !important;
  top: 57px !important;
}

.highlight {
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px,
    rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px !important;
  border-radius: 14px;
  border: 2px solid var(--primary-4);
}

.tableHighlight,
.buttonHighlight,
.widgetsHighlight {
  background-color: var(--white);
}

.menuItemHighlight {
  padding: 8px 15px;
  margin: -10px 0;
  left: 0 !important;
}

.widgetsHighlight {
  width: 100% !important;
  height: 170px !important;
  top: 130px !important;
  left: 85px !important;
}

.buttonHighlight {
  width: 208px !important;
  left: 112px !important;
  top: 400.5px !important;
}

.menuTooltip,
.tooltip {
  width: 30rem !important;
  max-width: unset !important;
  filter: drop-shadow(0px 2px 15px rgba(31, 24, 69, 0.1))
    drop-shadow(0px 2px 2px rgba(31, 24, 69, 0.1));

  & > div:nth-of-type(2) {
    max-width: 390px;
  }
}

.menuTooltip {
  top: 50%;
  transform: translateY(-50%);
}

.tooltip:not(.tableTooltip) {
  left: 80px !important;
}

.reportItemTooltip {
  width: 45.25rem !important;
  & > div:nth-of-type(2) {
    max-width: unset !important;

    & > div {
      display: flex;
      align-items: flex-start;
      gap: 1.5rem;

      & p {
        max-width: 625px;
        text-align: justify;
      }
    }
  }
}

.widgetsTooltip {
  top: 180px !important;
}

.buttonTooltip {
  top: 80px !important;
}

.modalHighlight {
  width: 42rem !important;
  height: 34rem !important;
  top: 50% !important;
  transform: translate(50%, -50%);
}

.modalHighlight,
.radioHighlight {
  right: 50% !important;
  left: unset !important;
}

.radioHighlight {
  width: 42rem !important;
  height: 114px !important;
  transform: translateX(50%);
}

.modalTooltip {
  width: 22.5rem !important;
  max-width: unset !important;
  filter: drop-shadow(0px 2px 15px rgba(31, 24, 69, 0.1))
    drop-shadow(0px 2px 2px rgba(31, 24, 69, 0.1));

  & > div:nth-of-type(2) {
    max-width: 350px;
  }
}

.viewButtons {
  padding: 0.375rem;
  position: fixed;
  left: 50%;
  bottom: 1rem;
  transform: translateX(-50%);
  background: var(--white);
  border: var(--border-base);
  border-radius: 0.625rem;
  z-index: 3;
  box-shadow: 0px 4px 8px 0px rgba(52, 42, 114, 0.08),
    0px 1px 2px 0px rgba(52, 42, 114, 0.08);

  & div {
    padding: 0.25rem;
    display: flex;
    gap: 0.25rem;
    background-color: var(--secondary-1);
    border-radius: 0.5rem;
  }

  & span {
    line-height: 1;
    color: var(--secondary-5);
    padding: 0.375rem 0.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
  }
}

.activeText {
  color: var(--primary-4) !important;
  background-color: var(--white);
  border-radius: 0.375rem;
  box-shadow: 0px 4px 8px 0px rgba(52, 42, 114, 0.08),
    0px 1px 2px 0px rgba(52, 42, 114, 0.08);
}

.onPublishText,
.stoppedText {
  display: flex;
  align-items: center;
  gap: 0.375rem;
}

.onPublishText {
  color: var(--green-10);
}

.stoppedText {
  color: var(--secondary-6);
}

.budget {
  display: flex;
  gap: 0.25rem;
  min-width: max-content;
  white-space: nowrap;
}

.flexibleBudgetText {
  color: var(--secondary-5);
}

.store .row {
  justify-content: flex-start;
}

.learnMore {
  gap: 6px;
  color: var(--primary-4);
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
}

.spinner,
.errorWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
}

.errorWrapper {
  width: 100%;
  flex-direction: column;
  background-color: var(--red-1);
  border: 1px solid var(--red-3);
  border-radius: 12px;
  box-shadow: 0px 8px 20px 0px rgba(223, 60, 109, 0.05);
}

.errorIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: var(--red-5);
  border-radius: 50%;
  margin-bottom: 14px;
  box-shadow: 0px 0px 0px 4px #ffd9e6;

  & svg {
    width: 20px;
    height: 20px;
  }

  & path {
    fill: var(--white);
  }
}

.errorTitle {
  color: var(--red-5);
  line-height: 1;
}
