.cardContainer {
  border: 1px solid var(--secondary-2);
  border-radius: 12px;
  flex: 1 0 auto;

  & > div:first-child {
    height: 85px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px 14px;
  }
}

.cardContainer ul {
  background-color: var(--secondary-1);
  transition: 0.3s ease-in background-color;
  border-radius: 0 0 8px 8px;

  & li {
    margin: 2.5px 0 4.5px 0;

    & span {
      line-height: 1;
    }
  }
}

.selected ul {
  background-color: var(--primary-4);

  & .btn {
    color: var(--secondary-8);
  }
}

.btn {
  color: var(--secondary-5);
  font-weight: 700;
  width: 100%;
  display: inline-block;
}

.up,
.down,
.percentage {
  color: var(--secondary-7);
  font-size: 10px;
  line-height: 1;
}

.title {
  font-size: 14px;
  margin-right: 5px;
  color: var(--secondary-5);
  text-transform: capitalize;
}

.meta {
  display: flex;
  align-items: center;
  & span:last-child {
    font-family: "Poppins", sans-serif !important;
  }
}

.percentContainer {
  gap: 3px;
  min-width: 38px;
  padding: 4px;
  border-radius: 5px;
  background-color: var(--secondary-1);
}

.secondTitle {
  color: black !important;
  margin-top: 6px !important;
  margin-bottom: 5px !important;
  line-height: 1 !important;
}

.message {
  color: var(--secondary-4);
  line-height: 1;
}
