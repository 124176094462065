.infoCard {
  padding: 6px;
  border-radius: 14px;
  background-color: var(--secondary-1);
  border: none;
}

.infoCard div[class="ant-card-body"] {
  padding: 0;
}

.templateContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 14px;
}

.templateText {
  color: var(--secondary-6);
  font-weight: 500;
}

.templateRuleText {
  color: var(--secondary-5);
}

.templateRulesList {
  padding-inline-start: 24px;
  margin-bottom: 0;
}

.formatRulesContainer {
  background-color: white;
  padding: 14px;
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
