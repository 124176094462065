.container {
  height: 188px;
  border: 1px solid var(--primary-4);
  border-radius: 6px;
  margin-top: 10px;
}

.header {
  padding: 16px 32px 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.graphTitle {
  font-size: 18px;
  color: var(--black) !important;
  margin-bottom: 5px !important;
  font-family: "Poppins", sans-serif !important;
}

.text {
  color: var(--secondary-4);
}

.row,
.skeletonRow {
  flex-flow: row nowrap;
  gap: 10px;
  width: 100%;
  overflow-x: auto;
  position: relative;
}

.skeletonRow {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.skeletonRow::-webkit-scrollbar {
  display: none;
}

.row.scroll {
  mask-image: linear-gradient(to left, transparent, black 82px, black);
  -webkit-mask-image: linear-gradient(to left, transparent, black 82px, black);
}

.advertiserRow {
  margin-top: 82px;
}

.title {
  color: var(--black) !important;
  margin-top: 10px;
  margin-bottom: 5px !important;
}

.skeletonCard,
.card {
  border: 1px solid var(--secondary-2);
  display: flex;
  align-items: center;
  cursor: pointer;
}

.skeletonCard {
  width: 100%;
  height: 109px;
  border-radius: 12px;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 0 20px;
  }

  & > div > div {
    line-height: 1;
  }

  & span {
    display: inline-block;
    background-color: var(--secondary-1) !important;
    border-radius: 100px;
  }
}

.card:last-of-type {
  border-radius: 10px;
  min-width: 50px;
  justify-content: center;
  transition: 0.3s ease-in-out;
  z-index: 2;

  & div[class="ant-card-body"] {
    padding: 0;
    width: 24px;
    height: 24px;
  }
}

.card.selectable {
  background-color: var(--primary-2);
  border: 1px solid var(--primary-6);
}

.icon {
  width: inherit;
  height: inherit;
}

.up {
  cursor: pointer;
}

.pickerContainer {
  flex-wrap: nowrap;
  gap: 0.5rem;
  margin-bottom: 0.75rem;
}

.rangeTextContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.rangeTitle {
  color: var(--black) !important;
}

.rangeDescription {
  display: inline-block;
  color: var(--secondary-4) !important;
}

.picker {
  flex-shrink: 0;
}
