.creativeDrawerForm {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 24px;
}

.creativeDrawerFormSection {
  flex: 0 0 calc(50% - 10px);

  & > div:first-of-type {
    margin-bottom: 0;
  }
}

.creativeDrawerFormSection:nth-child(1):nth-last-child(1) {
  flex: 0 0 100%;
}

.creativeDrawerFormSection:nth-child(3) {
  flex: 0 0 100%;
}

.drawerText {
  display: block;
  color: var(--secondary-4);
  margin: 8px 0 12px;
}

.creativeDrawerInput {
  width: 100% !important;
}

.tags > div:first-of-type,
.sizes > div:first-of-type {
  margin-top: 2px;
}

.tags > div:first-of-type {
  min-height: 50px;
  padding: 4px 10px !important;
}

.tags div[class="ant-select-selection-search"] {
  -webkit-margin-start: 0;
  margin-inline-start: 0;
}

.sizes > div:first-of-type {
  height: 50px !important;
}
