.container {
  width: 100%;
}

.containerDrawer {
  padding-inline: 14px;
}

.sizesTagContainer {
  display: flex;
  padding-bottom: 16px;
  flex-wrap: wrap;
  gap: 6px;
}

.sizesTagFormContainer {
  justify-content: center;
}

.infoContainer {
  display: flex;
  justify-content: space-between;
  padding: 14px;
  background-color: var(--primary-1);
  border-radius: 0 0 15px 15px;
}

.infoDrawerContainer {
  background-color: var(--secondary-1);
  border-radius: 12px;
}

.linkContainer {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}

.linkContainer span {
  color: var(--primary-4);
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  text-decoration: underline;
}

.linkContainer svg path {
  fill: var(--primary-4);
}

.creativeRecommendedSizeTitle {
  color: var(--secondary-4);
}

.creativeRecommendedSizeText {
  color: var(--secondary-5);
}

.tag {
  border-radius: var(--size-radius);
  border: none;
  margin: 6px 0 0;
}

.tagFormText {
  background-color: var(--primary-2);
  span {
    color: var(--primary-4);
  }
}

.tagDrawerText {
  background-color: var(--secondary-1);
  span {
    color: var(--secondary-6);
  }
}

.divider {
  margin: 0 0 8px 0 !important;
  line-height: 12px !important;
}
