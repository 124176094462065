.header {
  position: relative;
  background-color: var(--primary-2);
  box-shadow: 0px 2px 15px 0px rgba(30, 19, 92, 0.03),
    0px 2px 2px 0px rgba(30, 19, 92, 0.03);
  border-bottom: 1px solid var(--primary-3);
  margin: -24px -24px 24px -24px;
  padding: var(--size-lg) var(--size-lg) var(--size-md) var(--size-lg);
  z-index: 2;

  & h2 {
    color: var(--black) !important;
    font-size: 2rem;
    line-height: 1.18;
  }
}

.header.paused {
  background-color: var(--secondary-1);
  border-color: var(--secondary-3);
  box-shadow: 0px 2px 15px 0px rgba(30, 19, 92, 0.03),
    0px 2px 2px 0px rgba(30, 19, 92, 0.03);
}

.bottomBar {
  gap: 0.75rem;
  align-items: center;

  & div:not(.select) {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }

  & > div,
  > .label {
    padding-right: 4px;
    height: 32px;
    border: var(--border-base);
    background-color: var(--white);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    line-height: 1;
    position: relative;
    margin-top: 12px;

    & > span:first-of-type {
      border-right: var(--border-base);
      background-color: var(--secondary-1);
      padding: 0 10px;
      height: 100%;
      color: var(--secondary-6);
      font-weight: 600;
      border-radius: 8px 0 0 8px;
    }
  }
}

.paused .bottomBar {
  & > div,
  > .label {
    & > span:first-of-type {
      color: var(--secondary-4);
    }

    & span {
      color: var(--secondary-5);
    }

    & svg path {
      fill: var(--secondary-4);
    }
  }
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 3px !important;
}

.formItem {
  margin-bottom: 10px;
}

.label {
  color: var(--black);
}

.label {
  display: flex;
  align-items: center;
}

.bigIcon {
  width: 28px;
  height: 28px;

  & svg {
    width: 12px;
  }
}

.smallIcon {
  width: 22px;
  height: 22px;

  & svg {
    width: 10px;
  }
}

.iconContainer {
  background-color: var(--white);
  border: var(--border-base);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--size-radius);
  box-shadow: 0px 2px 15px 0px rgba(30, 19, 92, 0.03),
    0px 2px 2px 0px rgba(30, 19, 92, 0.03);
  margin-left: 1rem;
  cursor: pointer;

  & svg path {
    fill: var(--primary-6);
  }
}

.iconContainer.uneditable {
  background-color: var(--secondary-1);
  pointer-events: none;

  & svg path {
    fill: var(--secondary-4);
  }
}

.row {
  padding: var(--size-s);
  gap: 10px;
  background-color: var(--secondary-2);
  border-radius: 10px;

  & > div:not(:last-child) {
    margin-bottom: 10px;
  }
}

.row:not(:last-child) {
  margin-bottom: 1rem;
}

.hidePrefix span[class="ant-input-prefix"] {
  display: none;
}

.failedContainer {
  padding: 1rem;
  background-color: var(--red-1);
  border: 1px solid var(--red-5);
  border-radius: 0.5rem;
}

.failedText {
  color: var(--red-5);
}

.disabled {
  background-color: var(--secondary-1);
  pointer-events: none;
  cursor: not-allowed;

  & svg path {
    fill: var(--secondary-3) !important;
  }
}
