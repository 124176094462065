.space {
  width: 100%;
  justify-content: space-between;
  margin-bottom: 14px;
}

.spaceBig div:nth-of-type(2) {
  width: 80%;
}

.spaceSmall div:first-of-type {
  width: 100%;
}

.notSearch > div {
  justify-content: flex-end;
  display: flex;
}

.btn.add {
  min-width: 12.1875rem;
  justify-content: center;
}

.container {
  position: relative;
}

.tagContainer {
  margin-top: 4px;
  gap: 10px;
  display: inline-flex;
}

.table {
  & div[class="ant-table-content"] {
    max-height: 100vh;
    overflow: auto !important;
  }

  & > div > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 50vh;
  }

  & thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: var(--white);

    & > tr > th {
      border-bottom: 1px solid var(--secondary-2);
    }
  }
}

.action {
  cursor: pointer;
  padding-top: 10px;
  box-sizing: content-box;
  width: 1.125rem;
  height: 1.125rem;
}

.btnContainer {
  display: flex;
  gap: 10px;
}
