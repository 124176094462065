.title {
  margin-bottom: 0 !important;
}

.darkTitle {
  color: var(--secondary-2) !important;
}

.darkIcon path {
  fill: var(--secondary-2);
}

.row {
  justify-content: space-between;
}

.cancelBtn {
  min-width: 9.375rem;
}

.okBtn {
  min-width: 16rem;
}

.biggerDrawer {
  min-width: fit-content;
  gap: 0.5rem;
  max-width: 310px;
  margin-left: auto;
}

.biggerDrawer .cancelBtn {
  min-width: 120px;
}

.biggerDrawer .okBtn {
  min-width: 180px;
}

.secondaryOk {
  min-width: calc(100% - 1rem);
  border-radius: 0.625rem;
  margin: 0 0.5rem 0.875rem;
  color: var(--secondary-7);
  border: var(--border-base);
  background: var(--white);
  box-shadow: 0px 2px 2px 0px rgba(30, 19, 92, 0.03),
    0px 2px 15px 0px rgba(30, 19, 92, 0.03);
}

.advertiser {
  padding: 1.5rem;
  background-color: var(--red-1);
  border-top: 1px solid var(--red-3);
  width: calc(100% + 2rem);
  margin: -1rem;

  & .okBtn {
    min-width: 15rem;
  }

  & .cancelBtn,
  & .cancelBtn:hover {
    background-color: var(--white) !important;
  }
}

.advertiserWarning {
  width: 100%;
  margin-bottom: 1.25rem;

  & span {
    color: var(--secondary-7);
  }
}
