.customNotificationWrapper {
  padding: 14px;
  display: flex;
  justify-content: space-between;
  gap: 14px;
  cursor: pointer;
  border-bottom: 1px solid var(--secondary-2);
  transition: all 0.3s ease;
}

.textContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* max-width: 384px; */
}

.subject,
.body {
  display: inline-block;
  word-break: break-word;
}

.subject {
  color: var(--secondary-5);
  line-height: 1.2;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;

  &.read {
    gap: 0;
  }
}

.blueDot {
  width: 4px;
  height: 4px;
  flex-shrink: 0;
  background-color: var(--blue-5);
  border-radius: 50%;
  opacity: 1;
  transition: all 0.1s ease-in-out;

  &.hidden {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.body {
  color: var(--secondary-4);
  font-size: 11px;
  line-height: 17px;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: height 0.3s ease; */

  /*  &.expanded {
    white-space: normal;
    overflow: visible;
    text-overflow: unset;
  } */
}

.createdAt {
  color: var(--secondary-3);
  font-size: 11px;
  text-transform: capitalize;
  position: relative;
  margin-right: 16px;

  &::after {
    content: "";
    width: 1px;
    height: 14px;
    position: absolute;
    top: 50%;
    right: -16px;
    transform: translateY(-50%);
    background-color: var(--secondary-2);
  }
}

.iconsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-top: 12px;
}

.disabled {
  cursor: not-allowed;
}

/* .expandIcon {
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  margin-top: 1px;
  transition: transform 0.3s ease;

  & svg path {
    fill: var(--secondary-5);
  }
}

.expandIcon.expanded {
  transform: rotate(-180deg);
} */

.archiveIcon {
  width: 14px;
  height: 14px;

  & path {
    fill: var(--secondary-5);
  }

  &.read path {
    fill: var(--secondary-4);
  }
}

.actionIcon {
  position: relative;

  /* &.expanded {
    margin-left: unset;
  } */

  &::after {
    content: "";
    width: 1px;
    height: 7px;
    position: absolute;
    top: 50%;
    right: -9px;
    transform: translateY(-50%);
    background-color: var(--secondary-2);
  }
}
