.btn,
.checkbox {
  display: flex;
}

.checkbox {
  flex-direction: column;
  gap: 0.85rem;
}

.checkbox label {
  margin-right: 0 !important;
  gap: 0.5rem;
}

.btn {
  min-width: 7.375rem;
  gap: 0.5rem !important;
}

.text {
  gap: 1em;
  padding: 0.7em 1em;
  margin-bottom: 0.1em;
  border-radius: 8px;
  color: var(--primary-6);
  cursor: pointer;
  text-decoration: underline 1px rgba(0, 0, 0, 0) !important;
  text-underline-offset: 2px;
  transition: text-decoration-color 250ms !important;

  &:hover {
    text-decoration-color: inherit !important;
  }
}

.archive {
  color: var(--red-5);
  font-weight: 500;
}

.link {
  color: var(--primary-6) !important;
  &:hover {
    color: var(--primary-6) !important;
  }
}

.divider {
  margin: 0.25em 0 0.4em -0.25rem;
  background-color: var(--secondary-2);
  width: calc(100% + 0.5rem);
}

.filterBtn {
  min-width: 7rem;
  min-height: 2.25rem;
  float: right;
  gap: 0;
}

.col {
  width: 100%;
  flex-direction: column;
  min-height: 306px;
}

.popover {
  padding: 18px;
  border-radius: 20px;
  right: calc(24px + 1rem);
  box-shadow: 0px 2px 15px 0px rgba(31, 24, 69, 0.1),
    0px 2px 2px 0px rgba(31, 24, 69, 0.1);
  width: 400px;
}

.filter,
.input,
.datePicker {
  width: 100% !important;
}

.input:disabled {
  background: var(--secondary-1);
}

.input,
.datePicker {
  padding: 10px 15px !important;
  &::placeholder,
  input::placeholder {
    font-family: "Poppins", sans-serif !important;
    font-weight: 500 !important;
    color: var(--secondary-4) !important;
  }
}

.datePicker {
  border: var(--border-base);
  border-radius: var(--size-radius);
}

.title {
  font-size: 18px !important;
  color: var(--primary-6) !important;
  line-height: 1 !important;
}

.filter div[class="ant-select-selector"] {
  padding: 6px 15px !important;
}

.btnGroup {
  margin-top: 20px;
  justify-content: flex-end;

  & button {
    padding: 0 !important;
    width: 100%;
    min-height: 36px !important;
  }

  & button:first-of-type {
    max-width: 100px;
  }
}

.flex {
  display: flex !important;
  width: 4.5rem;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.invite {
  order: -1;

  & svg {
    width: 1rem;
    height: 1rem;
  }
}

.value {
  display: flex;
  align-items: center;

  & > div {
    min-width: 184px;
    height: 44px !important;
    overflow-x: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }

    & > div {
      width: 100%;
      left: -6px;
      flex-wrap: nowrap;
    }

    & > span {
      left: 15px !important;
    }
  }
}

.advertisers {
  border-radius: 0.625rem;
  border: 1px solid var(--primary-4);
  background-color: var(--white);
  color: var(--primary-4);
  width: 100%;
  line-height: 1;
  margin-top: 0.5rem;
}

.advertiserPopover {
  right: 24px;
}

.wrapper {
  position: relative;
  border: 1px solid var(--header-bg);
  user-select: none;
  min-width: 160px;
}

.withoutBalance {
  margin-left: auto;
}

.iconUp,
.iconDown {
  width: 0.85rem;
  height: 0.85rem;
  margin-left: 0.45rem;
  flex-shrink: 0;
  transition: transform 0.3s;
}

.userIcon {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  justify-content: center;
  flex-shrink: 0;
}

.light .userIcon.light {
  background-color: var(--secondary-1);

  & svg path {
    fill: var(--secondary-6);
  }
}

.dark .userIcon.dark {
  background-color: var(--secondary-7);

  & svg path {
    fill: var(--secondary-5);
  }
}

.name {
  color: var(--secondary-6);
  font-size: var(--size-xs);
  font-weight: 700;
}

.mail {
  color: var(--secondary-4);
  font-size: var(--size-xxxs);
  font-weight: 500;
  margin-top: 0.25rem;
}

.dark .mail {
  color: var(--secondary-5);
}

.list,
.wrapperVisible {
  box-shadow: 0px 1px 2px 0px rgba(52, 42, 114, 0.08),
    0px 4px 8px 0px rgba(52, 42, 114, 0.08);
}

.list {
  position: fixed;
  top: 59px;
  min-width: max-content;
  background-color: var(--header-bg);
  width: 100%;
  list-style: none;
  border-radius: 0px 0px 0px 10px;
  padding: 0;

  & li {
    padding: 0.875rem;
    line-height: 1;
    cursor: pointer;
  }

  & li,
  li a {
    font-size: var(--size-xxs);
    font-weight: 500;
    color: var(--secondary-6);
    transition: all 0.25s ease-in-out;
  }
}

.wrapper.light .list.light {
  border: 1px solid var(--secondary-2);

  & li,
  li a {
    color: var(--secondary-6);
  }

  & li + li {
    border-top: 1px solid var(--secondary-2);
  }

  & svg path {
    fill: var(--secondary-6);
    transition: fill 0.25s ease-in-out;
  }

  //Hover

  & li:hover {
    background-color: var(--secondary-1);
  }

  & li:not(:last-of-type):hover {
    color: var(--secondary-7);

    & a {
      color: var(--secondary-7) !important;
    }

    & svg path {
      fill: var(--secondary-7);
    }
  }
}

.wrapper.dark .list.dark {
  border: 1px solid var(--secondary-7);

  & li,
  li a {
    color: var(--secondary-3);
  }

  & li + li {
    border-top: 1px solid var(--secondary-7);
  }

  svg path {
    fill: var(--secondary-3);
  }

  //Hover

  & li:hover {
    background-color: var(--secondary-7);
  }

  & li:not(:last-of-type):hover {
    color: var(--secondary-1);

    & a {
      color: var(--secondary-1) !important;
    }

    & svg path {
      fill: var(--secondary-1);
    }
  }
}

.wrapperVisible.light {
  border: 1px solid var(--secondary-2);
}

.wrapperVisible.dark {
  border: 1px solid var(--secondary-7);
}

.wrapper.wrapperVisible .list li:last-of-type {
  color: var(--red-5);
  border-radius: 0 0 0 10px;

  & svg path {
    fill: var(--red-5);
  }
}

.adminList {
  top: 125px;
}

.wrapperVisible .iconUp {
  transform: rotate(-180deg);
}

.filterDropdownInfo {
  margin-bottom: 20px;
}

.filterDropdownText {
  color: var(--secondary-4);
  line-height: 1.47;
}

.container {
  width: 100%;
  max-width: 402px;
  padding: 1rem;
  cursor: pointer;
  position: relative;
  z-index: 10;
  background-color: var(--header-bg);

  & h5,
  p {
    line-height: 1;
    margin-bottom: 0;
  }
}

.container.light {
  border-left: 1px solid var(--secondary-2);
}

.container.dark {
  border-left: 1px solid var(--secondary-7);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(3px);
  pointer-events: none;
  opacity: 0;
  transition: opacity ease-in-out 0.2s;
}

.overlayVisible {
  opacity: 1;
}

.overlayFilter {
  z-index: 1001;
}
